import React from 'react';
import '../styles/hero.scss';
import FistLogo from '../assets/logos/fist.svg';
import SpeechBubble from '../assets/icons/icon-speech.svg';
import Arrow from '../assets/icons/icon-arrow.svg';
import { StaticImage } from 'gatsby-plugin-image';


const Hero = ({heading, subheading, buttons, leadMagnet}) => {

    const scrollTo = (selector) => {
        document.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <section className="hero">
            <div className="content">
                <div className="logo">
                    <FistLogo />
                </div>
                <h2 className="ff heading">
                    {heading}
                </h2>
                <p className="ff">
                    {subheading}
                </p>
            </div>
            <div className="buttons">
                <button className="ff ff-secondary" onClick={() => scrollTo(buttons[0].link)}>
                    <SpeechBubble />
                    <span>{buttons[0].text}</span>
                </button>
                <button className="ff ff-primary" onClick={() => scrollTo(buttons[1].link)}>
                    <span>{buttons[1].text}</span>
                    <Arrow />
                </button>
            </div>
            {leadMagnet && (
                <div className="spin-lead-magnet" onClick={() => scrollTo('#lead-magnet')}>
                    <span>4</span>
                    <StaticImage src="../assets/icons/free-audits-circle.png" alt="Spin Lead Magnet" />
                </div>
            )}
            <StaticImage src="../assets/team/team-cut-out.png" alt="54 Bit Studios Team" objectFit="contain" quality={100} layout="constrained" className="team-cut-out" loading="eager" placeholder="none"/>
        </section>
    )

}

export default Hero